body,
a:link,
a:visited,
a:hover,
a:active {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: whitesmoke;
    background-color: black;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    cursor: pointer;
}

.sensor-details {
    font-size: 14px;
}

header {
    text-align: right;
    width: 100%;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-transform: uppercase;
}

header a {    
    padding: 6px;
}

.sensor {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.sensor-details {
    margin-left: 1em;
}

.sensor-details .battery::before {
    content: "🔋";
}

.sensor-details .rssi::before {
    content: "📶";
}

.history {
    margin-top: 1em;
}

.sensor-status .sensor-name,
.history-entry .sensor-name,
.history-entry .timestamp,
.history-entry .measurement {
    display: inline-block;
}

.history-entry .measurement {
    padding-left: 1em;
    font-size: 1.2em;
    text-transform: uppercase;
}

.sensor-status .measurement {
    display: inline-block;
    padding: 6px;
    font-size: 1.2em;
    text-transform: uppercase;
}

.sensor-status.disabled,
.sensor-status.disabled .sensor-name,
.sensor-status.disabled .measurement {
    opacity: 0.75;
    cursor: default;
    text-decoration: line-through;
}

.sensor-status .measurement .Temperature::after, 
.history-entry .measurement .Temperature::after {
    content: "\0000B0" "C";
}

.sensor-status .measurement .RelativeHumidity::after,
.history-entry .measurement .RelativeHumidity::after {
    content: "%";
}

.sensor-status .measurement .BatteryVoltage::after,
.history-entry .measurement .BatteryVoltage::after {
    content: "V";
}

.sensor-status .measurement .Rssi::after,
.history-entry .measurement .Rssi::after {
    content: "db";
}

@media only screen and (max-width: 500px) {
    #content {
        width: 100%
    }
}
@media only screen and (min-width: 501px) {
    #content {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}